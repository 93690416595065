import { useEffect, useRef, useState } from 'react';
import { AccordionData } from './AccordionData';
import '../style/misc/accordion.scss';

function AccordionItem({data,btnOnClick}:{data:AccordionData;btnOnClick:()=> void }) {

  const contentRef = useRef<HTMLDivElement>(null);
  const [height, setHeight] = useState(0);

  useEffect(() => {
    if (data.status && contentRef.current) {
      const newHeight = contentRef.current.scrollHeight;
      setHeight(newHeight);
    } else {
      setHeight(0);
    }
  }, [data.status, data.content]);

  useEffect(() => {
    const handleResize = () => {
      if (data.status && contentRef.current) {
        const newHeight = contentRef.current.scrollHeight;
        setHeight(newHeight);
      }
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [data.status, data.content]);

  return ( 
    <li className={`arrayAbout ${data.status ? 'active' : ''}`}>       
      <div className='aboutBlock'>     
        <h2 className="aboutTitles">
          <div className='pointTitles'></div>
            <button className="accordionItemBtn" onClick={btnOnClick}> {data.title} </button>
          <div className='stickersBlock'> {data.stickers} </div>
        </h2>
        <div className="aboutContent" style={{ height }}>
          <div ref={contentRef} className="accordionItemContent"> {data.content} </div>
        </div>
      </div>   
    </li>
  );
  
}

export default AccordionItem;