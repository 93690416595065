// import { useState} from 'react';
import { CardGalleryData } from "./CardGalleryData";
import CardGalleryItem from "./CardGalleryItem";

function CardGallery({ items,expandedIndex,onItemClick}:{ items: Array<CardGalleryData>, expandedIndex: string | null, onItemClick: (id: string) => void  }) {
    
    // const nonExpandedItems = items.filter((_, index) => index !== expandedIndex);

    return (
        <div className="CardMainContainer">
            <div className="expandedGalleryContainer">
                {expandedIndex && (
                    <div className="expandedGalleryContainer">
                        <CardGalleryItem
                            data={items.find(item => item.id === expandedIndex)!}
                            onClick={() => onItemClick(expandedIndex)}
                            expanded={true}
                        />
                    </div>
                )}
            </div>

            <div className="nonExpandedGalleryContainer">
                {items.map((item) => (
                    <div key={item.id} className={`arrayGallery ${item.id === expandedIndex ? 'expanded' : ''}`}>
                        <CardGalleryItem
                            data={item}
                            onClick={() => onItemClick(item.id)}
                            expanded={false}
                        />
                    </div>
                ))}
            </div>
        </div>
    )
}
export default CardGallery;