import {useState, useEffect} from "react";
import { Link, useLocation } from "react-router-dom";
import { useLang } from "../components/LangContext";

export const Navbar = () => {
    const [activeLink, setActiveLink] = useState<string>("");
    const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
    const location = useLocation();
    const { lang } = useLang();
  
    useEffect(() => {
      const pathname = location.pathname.replace("/", "");
      setActiveLink(pathname || "accueil");
      setIsMenuOpen(false);  // Close menu on route change
    }, [location.pathname]);
  
    const texts: { [key: string]: { [key: string]: string } } = {
        en: {
          home: "Home",
          about: "About",
          skills: "Skills",
          gallery: "Gallery",
          contact: "Contact",
        },
        fr: {
          home: "Accueil",
          about: "À Propos",
          skills: "Compétences",
          gallery: "Galerie",
          contact: "Contact",
        },
      };
    
    const toggleMenu = () => {
      setIsMenuOpen(!isMenuOpen);
    };  
    const handleLinkClick = () => {
      setIsMenuOpen(false);
    };
  
    return (
      <nav className={`navbar ${isMenuOpen ? 'open' : ''}`}>
        <div className="menu-icon" onClick={toggleMenu}>
          ☰
        </div>
        <div className="navLinksBlock">
          <div className={`lien ${activeLink === "contact" ? "active" : ""}`}>
            <Link to="/contact" onClick={handleLinkClick}>{texts[lang].contact}</Link>
          </div>
          <div className={`lien ${activeLink === "gallery" ? "active" : ""}`}>
            <Link to="/gallery" onClick={handleLinkClick}>{texts[lang].gallery}</Link>
          </div>
          <div className={`lien ${activeLink === "skills" ? "active" : ""}`}>
            <Link to="/skills" onClick={handleLinkClick}>{texts[lang].skills}</Link>
          </div>
          <div className={`lien ${activeLink === "about" ? "active" : ""}`}>
            <Link to="/about" onClick={handleLinkClick}>{texts[lang].about}</Link>
          </div>
          <div className={`lien ${activeLink === "accueil" ? "active" : ""}`}>
            <Link to="/" onClick={handleLinkClick}>{texts[lang].home}</Link>
          </div>
        </div>
      </nav>
    );
  };