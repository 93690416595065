import { CardGalleryData } from './CardGalleryData';

type CardGalleryItemProps = {
    data: CardGalleryData;
    onClick: () => void;
    expanded: boolean;
};

function CardGalleryItem({ data, onClick, expanded }: CardGalleryItemProps) {
  
  return ( 
      <div className={`arrayGallery ${data.status ? 'active' : ''}${expanded ? 'expanded' : ''}`} onClick={onClick}>       
            <div className='arrayGalleryImg'>
                <div className='imgContainer'>{expanded && data.imageLogoExpanded ? data.imageLogoExpanded : data.imageLogo}</div>
            </div> 
            {expanded && (
                <div className='arrayGalleryBlock'>
                    <div className='arrayGalleryTitle'>{data.title}</div>
                    <div className='arrayGalleryDetail'>{data.detail}</div>
                </div>
            )}
      </div>
  );
}

export default CardGalleryItem;