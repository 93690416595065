import { useLang } from '../components/LangContext';

export const Home = () => {
    const { lang } = useLang();
  
    const texts: { [key: string]: { [key: string]: JSX.Element } } = {
      en: {
        helloWorld: <>Hello World</>,
        name: <>I'm Tom, also known as Grød404.</>,
        role: <><span className='highlight'>FullStack Developer</span></>,
        passion: <>Passionate about computer science in general and graphic arts.</>,
        welcome: <>Welcome to my website, looking forward to collaborating with you.</>,
      },
      fr: {
        helloWorld: <>Bonjour le monde</>,
        name: <>Je suis Tom, alias Grød404.</>,
        role: <><span className='highlight'>Développeur FullStack</span></>,
        passion: <>Passionné par l'informatique en général et les arts graphiques.</>,
        welcome: <>Bienvenue sur mon site, au plaisir de collaborer avec vous.</>,
      },
    };
  
    return (
      <section className="HomeContainer">
        <div className="HomeTexts">
        <div className="helloWorld"></div>
          <p>{texts[lang].name}</p>
          <p>{texts[lang].role}</p>
          <p>{texts[lang].passion}</p>
          <p>{texts[lang].welcome}</p>
        </div>
      </section>
    );
  };