import React from 'react';
import { useLang } from '../components/LangContext';

export const LanguageButton: React.FC = () => {
  const { lang, toggleLang } = useLang();

  return (
    <div className="LgMainContainer">
      <div className="ChoicesLg">
        <div className={`FrenchChoice ${lang === 'fr' ? 'active' : ''}`} onClick={toggleLang}>
          <img 
            width="48" 
            height="48" 
            src="https://img.icons8.com/color/48/france-circular.png" 
            alt="Français"
          />
        </div>
        <div className={`EnglishChoice ${lang === 'en' ? 'active' : ''}`} onClick={toggleLang}>
          <img 
            width="48" 
            height="48" 
            src="https://img.icons8.com/color/48/great-britain-circular.png" 
            alt="English"
          />
        </div> 
      </div>
    </div>
  );
};