import Accordion from '../components/Accordion';
import '../style/pages/About.scss';
import { useLang } from '../components/LangContext';

export const About = () => {
    const { lang } = useLang();
    

    const accordionItems = [
        {
            title: "2011",
            content:(<div className='contentBlockAbout'><h3>{lang === 'fr' ? "Bac STI Arts Appliqués, 'Bellecour Ecole', école d'Art." : "STI Applied Arts Baccalaureate, 'Bellecour Ecole', Art school."}</h3>
            <p>{lang === 'fr' ? "+'extra' en restauration, vendanges et pose de cuisine les week-end et vacances." : "+'extra' in restoration, grape harvesting and kitchen installation on weekends and holidays."}</p></div>), 
            status: false, 
            stickers: (<div className='stickBlock'><div className='workStick'></div><div className='studeStick'></div></div>),               
        },
        {
            title: "2012",
            content:(<div className='contentBlockAbout'><h3>{lang === 'fr' ? "Classe probatoire Emile Cohl, école d'Art." : "Emile Cohl preparatory class, Art school."}</h3>  
            <p>{lang === 'fr' ? "+'extra' en restauration et pose de cuisine les week-end et vacances." : "+'extra' in restoration and kitchen installation on weekends and holidays."}</p></div>),   
            status: false,
            stickers: (<div className='stickBlock'><div className='workStick'></div><div className='studeStick'></div></div>),   
        },
        {
            title: "2012-2014",
            content:(<div className='contentBlockAbout'><h3>{lang === 'fr' ? "CDI Serveur, Rue Mercière Lyon." : "Permanent contract as a waiter, Rue Mercière Lyon."}</h3>
            <p>{lang === 'fr' ? "+Intérim (missions diverses et variées),'extra' en restauration et pose de cuisine les week-end et vacances." : "+Temporary work (various and varied missions),'extra' in restoration and kitchen installation on weekends and holidays."}</p></div>), 
            status: false,  
            stickers: (<div className='stickBlock'><div className='workStick'></div></div>),   
        },
        {
            title: "2014-2016",
            content:(<div className='contentBlockAbout'><h3>{lang === 'fr' ? "Bachelor Infographie 3D, 1ere et 2 eme année, Bellecour Ecole, école d'Art." : "Bachelor's Degree in 3D Graphics, 1st and 2nd year, Bellecour Ecole, Art school."}</h3>
            <p>{lang === 'fr' ? "+'extra' en restauration les week-end et vacances." : "+'extra' in restoration on weekends and holidays."}</p></div>), 
            status: false, 
            stickers: (<div className='stickBlock'><div className='workStick'></div><div className='studeStick'></div></div>),      
        },
        {
            title: "2016-2018",
            content:(<div className='contentBlockAbout'><h3>{lang === 'fr' ? "CDI Livreur d'électroménager et de matériel électrique." : "Permanent contract as a delivery person for household appliances and electrical equipment."}</h3>
            <p>{lang === 'fr' ? "+'extra' en restauration les week-end et vacances." : "+'extra' in restoration on weekends and holidays."}</p></div>),      
            status: false, 
            stickers: (<div className='stickBlock'><div className='workStick'></div></div>), 
        },
        {
            title: "2018",
            content:(<div className='contentBlockAbout'><h3>{lang === 'fr' ? "Saison d'été en restauration, Cuisinier (8 mois 7/7)" : "Summer season in catering, Cook (8 months 7/7)"}</h3></div>), 
            status: false, 
            stickers: (<div className='stickBlock'><div className='workStick'></div></div>),     
        },
        {
            title: "2019-2022",
            content:(<div className='contentBlockAbout'><h3>{lang === 'fr' ? "Auto-Entrepreneur, Foodtruck de Burger." : "Self-employed, Burger Foodtruck."}</h3></div>), 
            status: false,  
            stickers: (<div className='stickBlock'><div className='workStick'></div></div>),
        },
        {
            title: "2022-2023",
            content:(<div className='contentBlockAbout'><h3>{lang === 'fr' ? "Bac +2 de niveau 5, Développeur Web Fullstack, OpenClassroom." : "Level 5 Bac +2, Fullstack Web Developer, OpenClassroom."}</h3>
            <p>{lang === 'fr' ? "+Intérimaire (Magasinier, Poseur en Métallurgie, Opérateur en Industrie (Peinture Industrielle et Cosmétique))." : "+Temporary worker (Storekeeper, Metalworker, Industrial Operator (Industrial and Cosmetic Painting))."}</p></div>),    
            status: false,
            stickers: (<div className='stickBlock'><div className='workStick'></div><div className='studeStick'></div></div>),
        },
        {
            title: "2024",
            content: (
                <div className='contentBlockAbout'>
                    <h3>
                        {lang === 'fr' ? "Votre futur Junior !" : "Your future Junior!"}
                    </h3>
                </div>
            ),
            status: true,
            stickers: null,
        }

    ]

    return (
        
        <section className="AboutContainer">       
            <div className='AccordionMainContainer'><Accordion items={accordionItems} lang={lang}/></div>            
        </section>
        
    )
    }