import { useState } from "react";
import CardGallery from "../components/CardGallery";
import { useLang } from '../components/LangContext';

export const Gallery = () => {
    const { lang } = useLang();
    const [expandedIndex, setExpandedIndex] = useState<string | null>(null);

    const handleItemClick = (id: string) => {
        setExpandedIndex(id === expandedIndex ? null : id);
    };

    const projectTexts: Record<string, any[]> = {
        fr: [
            {
                id: "1",
                title: "Booki",
                detail: (<div className="cardDetailData">
                            <div className="textDetailData">Dans ce projet, il fallait simplement respecter une maquette imposée (responsive compris) en créant les fichiers CSS nécessaires.</div>
                            <div className="linkDetailBlock">
                                <a className="linkDetailData" href="https://tomnoam.github.io/Booki_OC/#hebergements" target="_blank" rel="noreferrer">Lien vers le Site</a>
                                <a className="linkDetailData" href="https://github.com/TomNoam/Booki_OC" target="_blank" rel="noreferrer">Lien vers le Github</a>
                            </div>
                        </div>),
                status: true,
                imageLogo: (<img className="cardLogoProject" src='./images/Gallery_Images/booki01.webp' alt="Projet Booki"></img>),
                imageLogoExpanded: (<img className="cardLogoProject" src='./images/Gallery_Images/booki02.webp' alt="Projet Booki" />)
            },
            {
                id: "2",
                title: "Kanap",
                detail: (<div className="cardDetailData">
                            <div className="textDetailData">Dans ce projet, en HTML/CSS/JS, il était demandé de mettre en place dynamiquement le site à partir d'une API. Il fallait gérer le "panier", du choix, jusqu'à la commande (celle-ci transmise et validée par l'API).</div>
                            <div className="linkDetailBlock">
                                <a className="linkDetailData" href="https://github.com/TomNoam/Kanap_OC" target="_blank" rel="noreferrer">Lien vers le Github</a>
                            </div>
                        </div>),
                status: true,
                imageLogo: (<img className="cardLogoProject" src='./images/Gallery_Images/kanap01.webp' alt="Projet Kanap"></img>),
                imageLogoExpanded: (<img className="cardLogoProject" src='./images/Gallery_Images/kanap02.webp' alt="Projet Kanap" />)
            },
            {
                id: "3",
                title: "Kasa",
                detail: (<div className="cardDetailData">
                            <div className="textDetailData"> Kasa était le projet Final de la formation. A partir de maquettes il fallait réaliser le site complet en React. (L'arrivée sur une 404 à la première connexion était une contrainte de l'exercice.)</div>
                            <div className="linkDetailBlock">
                                <a className="linkDetailData" href="https://tomnoam.github.io/Kasa_OC_D/" target="_blank" rel="noreferrer">Lien vers le Site</a>
                                <a className="linkDetailData" href="https://github.com/TomNoam/Kasa_OC_D" target="_blank" rel="noreferrer">Lien vers le Github</a>
                            </div>
                        </div>),
                status: true,
                imageLogo: (<img className="cardLogoProject" src='./images/Gallery_Images/kasa01.webp' alt="Projet Kasa"></img>),
                imageLogoExpanded: (<img className="cardLogoProject" src='./images/Gallery_Images/kasa02.webp' alt="Projet Kasa" />)
            },
            {
                id: "4",
                title: "ohmyfood",
                detail: (<div className="cardDetailData">
                            <div className="textDetailData">Pour OhMyFood, des maquettes "mobile" étaient imposées, avec une liberté pour l'adaptation "Tablet" et "Desktop",
                            ainsi que l'écran de chargement à l'arrivée sur le site. Le tout en HTML/CSS avec découverte de SASS.</div>
                            <div className="linkDetailBlock">
                                <a className="linkDetailData" href="https://tomnoam.github.io/Oh_My_Food_OC/index.html" target="_blank" rel="noreferrer">Lien vers le Site</a>
                                <a className="linkDetailData" href="https://github.com/TomNoam/Oh_My_Food_OC" target="_blank" rel="noreferrer">Lien vers le Github</a>
                            </div>
                        </div>),
                status: true,
                imageLogo: (<img className="cardLogoProject" src='./images/Gallery_Images/ohmyfood01.webp' alt="Projet OhMyFood"></img>),
                imageLogoExpanded: (<img className="cardLogoProject" src='./images/Gallery_Images/ohmyfood02.webp' alt="Projet OhMyFood" />)
            }
        ],
        en: [
            {
                id: "1",
                title: "Booki",
                detail: (<div className="cardDetailData">
                            <div className="textDetailData">In this project, the goal was to strictly follow a given design (including responsiveness) by creating the necessary CSS files.</div>
                            <div className="linkDetailBlock">
                                <a className="linkDetailData" href="https://tomnoam.github.io/Booki_OC/#hebergements" target="_blank" rel="noreferrer">Link to Site</a>
                                <a className="linkDetailData" href="https://github.com/TomNoam/Booki_OC" target="_blank" rel="noreferrer">Link to Github</a>
                            </div>
                        </div>),
                status: true,
                imageLogo: (<img className="cardLogoProject" src='./images/Gallery_Images/booki01.webp' alt="Booki Project"></img>),
                imageLogoExpanded: (<img className="cardLogoProject" src='./images/Gallery_Images/booki02.webp' alt="Booki Project" />)
            },
            {
                id: "2",
                title: "Kanap",
                detail: (<div className="cardDetailData">
                            <div className="textDetailData">In this project, using HTML/CSS/JS, the task was to dynamically set up the site from an API. It was necessary to manage the "cart", from selection to order (which is sent and validated by the API).</div>
                            <div className="linkDetailBlock">
                                <a className="linkDetailData" href="https://github.com/TomNoam/Kanap_OC" target="_blank" rel="noreferrer">Link to Github</a>
                            </div>    
                        </div>),
                status: true,
                imageLogo: (<img className="cardLogoProject" src='./images/Gallery_Images/kanap01.webp' alt="Kanap Project"></img>),
                imageLogoExpanded: (<img className="cardLogoProject" src='./images/Gallery_Images/kanap02.webp' alt="Kanap Project" />)
            },
            {
                id: "3",
                title: "Kasa",
                detail: (<div className="cardDetailData">
                            <div className="textDetailData">Kasa was the final project of the training. Based on mockups, the entire site had to be developed in React.(Encountering a 404 error on the first connection was a constraint of the exercise.)</div>
                            <div className="linkDetailBlock">
                                <a className="linkDetailData" href="https://tomnoam.github.io/Kasa_OC_D/" target="_blank" rel="noreferrer">Link to Site</a>
                                <a className="linkDetailData" href="https://github.com/TomNoam/Kasa_OC_D" target="_blank" rel="noreferrer">Link to Github</a>
                            </div>    
                        </div>),
                status: true,
                imageLogo: (<img className="cardLogoProject" src='./images/Gallery_Images/kasa01.webp' alt="Kasa Project"></img>),
                imageLogoExpanded: (<img className="cardLogoProject" src='./images/Gallery_Images/kasa02.webp' alt="Kasa Project" />)
            },
            {
                id: "4",
                title: "ohmyfood",
                detail: (<div className="cardDetailData">
                            <div className="textDetailData">For OhMyFood, "mobile" mockups were provided, with freedom for adaptation to "Tablet" and "Desktop", as well as the loading screen upon arrival on the site. All in HTML/CSS with the discovery of SASS.</div>
                            <div className="linkDetailBlock">
                                <a className="linkDetailData" href="https://tomnoam.github.io/Oh_My_Food_OC/index.html" target="_blank" rel="noreferrer">Link to Site</a>
                                <a className="linkDetailData" href="https://github.com/TomNoam/Oh_My_Food_OC" target="_blank" rel="noreferrer">Link to Github</a>
                            </div>
                        </div>),
                status: true,
                imageLogo: (<img className="cardLogoProject" src='./images/Gallery_Images/ohmyfood01.webp' alt="OhMyFood Project"></img>),
                imageLogoExpanded: (<img className="cardLogoProject" src='./images/Gallery_Images/ohmyfood02.webp' alt="OhMyFood Project" />)
            }
        ]
    };

    const cardGalleryOC = projectTexts[lang] || projectTexts['en']; 

    return (
        <section className="galleryContainer">
            <div className="OCContainer">
                <div className="CardGalleryOC">
                    <CardGallery items={cardGalleryOC} expandedIndex={expandedIndex} onItemClick={handleItemClick} />
                </div>
            </div>
        </section>
    );
}