import { useLang } from '../components/LangContext';

export const Contact: React.FC = () => {
    const { lang } = useLang();

    const contactTexts: Record<string, any> = {
        fr: {
            title: "Pour me contacter:",
            copySuccess: "Email copié dans le presse-papier",
            copyError: "Erreur lors de la copie dans le presse-papiers",
        },
        en: {
            title: "To contact me:",
            copySuccess: "Email copied to clipboard",
            copyError: "Error copying to clipboard",
        }
    };

    const { title, copySuccess, copyError } = contactTexts[lang] || contactTexts['en'];

    const textToCopy: string = "tomnoamadjedj@gmail.com";

    const copyToClipboard = () => {
        navigator.clipboard.writeText(textToCopy)
            .then(() => {
                console.log('Texte copié dans le presse-papiers');
                alert(copySuccess);
            })
            .catch((error) => {
                console.error('Erreur lors de la copie dans le presse-papiers : ', error);
                alert(copyError);
            });
    };

    return (
        <section className="contactContainer">
            <div className="titleContact">{title}</div>
            <div className="contactChoicesContainer">
                <div className="contactChoice">
                    <a className="linkeDinLink" href="https://www.linkedin.com/in/tom-noam-2a481010b/" target="_blank" rel="noopener noreferrer">
                        <img src="images/Contact_Images/Linkedin-logo.webp" alt="LinkedIn URL" />
                    </a>
                </div>
                <div className="contactChoice">
                    <img src="images/Contact_Images/Gmail-logo.webp" alt="Gmail Copy/Paste" onClick={copyToClipboard} />
                </div>
            </div>
        </section>
    );
}