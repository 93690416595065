import React, { useState, useEffect } from 'react';
import '../style/misc/backgroundMover.scss';

const BackgroundMover: React.FC = () => {
    const [backgroundPosition, setBackgroundPosition] = useState<{ x: number; y: number }>({ x: 50, y: 50 });

    const handleMouseMove = (e: MouseEvent) => {
        const { clientX: x, clientY: y } = e;
        const width = window.innerWidth;
        const height = window.innerHeight;

        // Calculer la position de fond en pourcentage
        const xPos = (x / width) * 20;
        const yPos = (y / height) * 20;

        setBackgroundPosition({ x: xPos, y: yPos });
    };

    useEffect(() => {
        window.addEventListener('mousemove', handleMouseMove);
        return () => {
            window.removeEventListener('mousemove', handleMouseMove);
        };
    }, []);

    return (
        <div
            className="background-container"
            style={{
                backgroundPosition: `${backgroundPosition.x}% ${backgroundPosition.y}%`
            }}
        >
        </div>
    );
};
export default BackgroundMover;