import "./fonts/good_timing_bd.otf";

import { Navbar } from './components/Navbar';

import {Routes, Route} from 'react-router-dom';

import { Home } from './pages/Home';
import { About } from './pages/About';
import { Skills } from './pages/Skills';
import { Contact } from './pages/Contact';
import { Gallery } from './pages/Gallery';
import { LanguageButton } from './components/LanguageButton';
// import { DarkLightButton } from "./components/DarkLightButton";
import { LangProvider } from './components/LangContext';
import BackgroundMover from "./components/BackgroundMover";

function App() {
  return (
    <LangProvider>
      <main>
        <section className="MainBlock">
          <BackgroundMover />
          <section className="NavbarBlock"><Navbar/></section>
          <section className="ButtonsChoices">
            {/* <div className="DarkLightBlock"><DarkLightButton/></div> */}
            <div className="LanguageBlock"><LanguageButton/></div>        
          </section>
          <section className="BlockRoutes">
            <Routes>
              <Route path='/' element={<Home/>}/>
              <Route path='/about' element={<About/>}/>
              <Route path='/skills' element={<Skills/>}/>         
              <Route path='/gallery' element={<Gallery/>}/>
              <Route path='/contact' element={<Contact/>}/>
            </Routes>
          </section>
        </section>
      </main>
    </LangProvider>
  );
}

export default App;
