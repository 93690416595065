export const ImagesSkills = () => {

    return(
        <div className="hardSkillsContainer">    
            <img src="images/Skills_Images/Html.webp" alt="Technologie HTML"></img>       
            <img src="images/Skills_Images/Css.webp" alt="Technologie CSS"></img>
            <img src="images/Skills_Images/JavaScript.webp" alt="Technologie JavaScript"></img>
            <img src="images/Skills_Images/React.webp" alt="Technologie React"></img>
            <img src="images/Skills_Images/Sass.webp" alt="Technologie SASS"></img>
            <img src="images/Skills_Images/NodeJS.webp" alt="Technologie NodeJS"></img>
            <img src="images/Skills_Images/NodeJsExpress.webp" alt="Technologie Express JS"></img>
            <img src="images/Skills_Images/TS.webp" alt="Technologie TypeScript"></img>
            <img src="images/Skills_Images/Adobe.webp" alt="Adobe Photoshop/After Effect"></img>
        </div>
    )
}