import {ImagesSkills} from "../components/ImagesSkills";
import { useLang } from '../components/LangContext';

export const Skills = () => {
    const { lang } = useLang();

    const softSkillsTexts: Record<string, string[]> = {
        fr: ["Déterminé", "Passionné", "Créatif"],
        en: ["Determined", "Passionate", "Creative"]
    };
    const softSkills: string[] = softSkillsTexts[lang] || softSkillsTexts['en'];

    const cvTextLang: Record<string, string[]> = {
        fr: ["Sélectionnez la langue du CV"],
        en: ["Select the language of the CV"]
    };
    const cvLang: string[] = cvTextLang[lang] || cvTextLang['en'];

    return (
        <section className="skillsMainContainer">
            <div className="titleSkills">CURRICULUM VITAE</div>
            <div className="cvTextContainer">
                {cvLang.map((lang: string, index: number) => (
                    <div className="cvText" key={index}>
                        {lang}
                    </div>
                ))}
            </div>
            <div className="CvContainer">
                <div className="cvFr">
                    <a className="cvFRpdf" href="./images/Flag_Images/CV_Dev_Web_FR.pdf" target="_blank" rel="noopener noreferrer" title="Téléchargez mon CV - Français">
                        <img 
                            src='./images/Flag_Images/frenchflag.webp'
                            alt="CV Français"
                        />
                    </a>
                </div>
                <div className="cvEng">
                    <a className="cvENGpdf" href="./images/Flag_Images/CV_Dev_Web_ENG.pdf" target="_blank" rel="noopener noreferrer" title="Download my CV - English">
                        <img 
                            src='./images/Flag_Images/englishflag.webp' 
                            alt="CV English"
                        />
                    </a>
                </div>
            </div>
            
            <div className="titleSkills">HARD SKILLS</div>
            <div className="skillImagesContainer">
                <ImagesSkills />
            </div>
            <div className="titleSkills">SOFT SKILLS</div>
            <div className="softSkillsContainer">
                {softSkills.map((skill: string, index: number) => (
                    <div className="softSkillText" key={index}>
                        {skill}
                    </div>
                ))}
            </div>
        </section>
    );
};