import { useState,useEffect,useRef } from 'react';
import { AccordionData } from './AccordionData';
import AccordionItem from './AccordionItem';
import '../style/misc/accordion.scss';

function Accordion({ items, lang }: { items: Array<AccordionData>, lang: string }) {
  const firstRender = useRef(true);
  const lastIdxRef = useRef(items.length - 1);

  const btnOnClick = (idx: number) => {
    const updatedItems = [...items];

    if (idx === lastIdxRef.current) {
      updatedItems[idx].status = true;
    } else {
      updatedItems[idx].status = !updatedItems[idx].status;
    }
    setItems(updatedItems);
  };

  const [currentItems, setItems] = useState(items);

  useEffect(() => {
    if (firstRender.current) {
    const lastIdx = currentItems.length - 1;
    const updatedItems = [...currentItems];
    
    updatedItems[lastIdx].status = true;
    setItems(updatedItems);
    firstRender.current = false;
    }
  }, [currentItems]);


  return (
    <div className='accordionContainer'>
        <div className='titlePBlock'>
            <div className='accordionTitle'>
                {lang === 'fr' ? "Parcours" : "Journey"}
            </div>
            <div className='workStick'></div>
            <div className='legendTitle'> 
                {lang === 'fr' ? "Travail" : "Work"}
            </div>
            <div className='studeStick'></div>
            <div className='legendTitle'> 
                {lang === 'fr' ? "Études" : "Studies"}
            </div>
        </div>
        <div className='ulContainer'>
            <div className='chronoBar'></div>
            <ul className="accordion">
                {items.map((item, idx) => (
                    <AccordionItem key={idx} data={item} btnOnClick={() => btnOnClick(idx)}/>
                ))}
            </ul>
        </div>
        <div className='titleShortBlock'>
            <div className='inShortTitle'> 
                {lang === 'fr' ? "En bref..." : "In brief..."}
            </div>
            <div className='inShortP'>
                {lang === 'fr' ? (
                    <p>Mes expériences variées dans de nombreux domaines différents m'ont appris à m'intégrer et à m'adapter rapidement. J'ai de plus, l'habitude d'être flexible sur les horaires et je n'ai pas peur des défis. Au contraire cela m'anime.</p>
                ) : (
                    <p>My various experiences in different fields have taught me to integrate and adapt quickly. I also have the habit of being flexible on schedules and I am not afraid of challenges. On the contrary, it motivates me.</p>
                )}
            </div>
        </div>
    </div>
)
}

export default Accordion;